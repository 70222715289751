import React, { useContext, useState } from 'react'
import StepDefault from "../../../components/StepDefault/StepDefault"
import Button from '../../../components/Button/Button'
import "./FourthStep.css"
import ContextApp from '../../../context/ContextApp'

const FourthStep = ({ onResetForm, dataFinal }) => {

  const { dataStep1 } = useContext(ContextApp)

  const [mlg, setMlg] = useState(undefined)

  const returnData = () => {
    if (dataStep1?.id === 3) {
      const { multiple } = dataFinal.gastoCaloricoTotal
      if (!!mlg && mlg !== "") {
        return Math.round((((25, 9 * mlg) + 284) * multiple) * 100) / 100
      }
    } else
      return Math.round(dataFinal.gastoCaloricoTotal * 100) / 100
  }

  return <StepDefault title={"Resultado #sovai"} styleTitle={{ textAlign: "center" }}>
    <>
      <div className='d-flex-column col'>

        {!!dataStep1
          && dataStep1?.id === 3
          && <>
            <label>Informe seu MLG para calcular</label>
            <input className="form-control mb-5" name="MLG" id="MLG"
              onChange={({ target: { value } }) => { setMlg(value) }} />
          </>}

        {<>
          <div className='card-result m-2 shadow-sm'>
            <div className='d-flex justift-content-around'>

              <div className='d-flex align-items-center'>
                <img src={require("../../../assets/icons/fire.png")} style={{ width: 50, height: 50, marginRight: 20 }} className={"rounded mx-auto d-block"} alt="fire_icon" />
              </div>

              <div className='card-text d-flex-column'>
                <h5 className='text-end text-pink'>{returnData()}</h5>
                <p className='font-weight-bold text-end text-muted'>gasto energético diário</p>
              </div>

            </div>
          </div>
          <div className='card-result m-2 shadow-sm'>
            <div className='d-flex justift-content-around'>

              <div className='d-flex align-items-center'>
                <img src={require("../../../assets/icons/protein.png")} style={{ width: 50, height: 50, marginRight: 20 }} className={"rounded mx-auto d-block"} alt="fire_icon" />
              </div>

              <div className='card-text d-flex-column'>
                <h5 className='text-end text-pink'>{dataFinal.proteinas}</h5>
                <p className='font-weight-bold text-end text-muted'>meta de proteína</p>
              </div>

            </div>
          </div>
        </>}
      </div>

      <div className='d-flex col mt-3'>
        <Button title={"Simular novamente"} onClick={onResetForm} second={true} />
      </div>
    </>
  </StepDefault >
}

export default FourthStep