// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-result {
  flex: 1 1;
  padding: 10px;
  /* border-style: solid; */
  border-width: 1px;
  border-radius: 10px;
}

.card-text {
  flex: 1 1;
}

.text-pink {
  color: #394937;
}
`, "",{"version":3,"sources":["webpack://./src/partials/Steps/FourthStep/FourthStep.css"],"names":[],"mappings":"AAAA;EACE,SAAO;EACP,aAAa;EACb,yBAAyB;EACzB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".card-result {\n  flex: 1;\n  padding: 10px;\n  /* border-style: solid; */\n  border-width: 1px;\n  border-radius: 10px;\n}\n\n.card-text {\n  flex: 1;\n}\n\n.text-pink {\n  color: #394937;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
