import FirstStep from "./FirstStep/FirstStep"
import SecondStep from "./SecondStep/SecondStep"
import ThirdStep from "./ThirdStep/ThirdStep"
import FourthStep from "./FourthStep/FourthStep"

export {
  FirstStep,
  SecondStep,
  ThirdStep,
  FourthStep
}