const RenderItem = ({ item, onClick, isActive = false }) => {
  const { title, subtitle } = item

  return <a href={"#"} onClick={() => onClick(item)} >
    <div className={`card card-item mb-4 ${!!isActive && isActive.id === item.id ? "card-item-active" : ""}`}>
      <div className="card-body">
        <p className={`text-capitalize text-item mb-0 ${!!isActive && isActive.id === item.id ? "text-item-active" : ""} `}>{title}</p>
        <p className={`text-capitalize  text-item small ${!!isActive && isActive.id === item.id ? "text-item-active" : ""}`}>{subtitle}</p>
      </div>
    </div>
  </a>
}


export default RenderItem