import React, { useContext } from 'react'
import ContextApp from '../../../context/ContextApp'
import UtilSteps from '../Utils'
import StepDefault from "../../../components/StepDefault/StepDefault"
import { Slider } from 'antd';
import Button from '../../../components/Button/Button'
import "./SecondStep.css"

const SecondStep = ({ onNextStep, onPrevStep }) => {

  const { setDataStep2 } = useContext(ContextApp)
  const { title } = UtilSteps('secondStep')

  const [data, setallData] = React.useState({ peso: 80, altura: 160, idade: 18 })

  const validateData = (!!data?.peso && !!data?.altura && !!data?.idade && !!data.sexo)

  return <StepDefault title={title}>
    <div className='col-12'>
      <label className='mt-4'>Gênero</label>
      <div className="d-flex">
        <div className="form-check m-2">
          <input className="input-anny form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="Feminino"

            onChange={() => {
              let oldData = data
              setallData({ ...oldData, sexo: 'Feminino' })
            }} />
          <label className="form-check-label" for="exampleRadios1">
            Feminino
          </label>
        </div>
        <div className="form-check m-2">
          <input className="input-anny form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="Masculino" onChange={() => {
            let oldData = data
            setallData({ ...oldData, sexo: 'Masculino' })
          }} />
          <label className="form-check-label" for="exampleRadios2">
            Masculino
          </label>
        </div>
      </div>
      <div className='d-flex justify-content-between'>
        <label className='mt-4'>Idade</label>
        {!!data?.idade && <label className='mt-4'>{data?.idade} anos</label>}
      </div>

      <Slider
        onChange={v => {
          let oldData = data
          setallData({ ...oldData, idade: v })
        }}
        defaultValue={18} max={90} min={2}
        trackStyle={{ background: "#394937" }} handleStyle={{ borderColor: "#394937" }} />


      <div className='d-flex justify-content-between'>
        <label className='mt-4'>Altura</label>
        {!!data?.altura && <label className='mt-4'>{data?.altura} cm</label>}
      </div>

      <Slider
        onChange={v => {
          let oldData = data
          setallData({ ...oldData, altura: v })
        }}
        defaultValue={160} max={230} min={130}
        trackStyle={{ background: "#394937" }} handleStyle={{ borderColor: "#394937" }} />


      <div className='d-flex justify-content-between'>
        <label className='mt-4'>Peso</label>
        {!!data?.peso && <label className='mt-4'>{data?.peso} kg</label>}
      </div>
      <Slider
        onChange={v => {
          let oldData = data
          setallData({ ...oldData, peso: v })
        }}
        defaultValue={80} max={180} min={40}
        trackStyle={{ background: "#394937" }} handleStyle={{ borderColor: "#394937" }} />

      <div className='d-flex mt-4'>
        <Button title={"Avançar"}
          onClick={() => {
            setDataStep2(data)
            onNextStep()
          }} disabled={!validateData} />
      </div>
      <div className='d-flex mt-3'>
        <Button title={"Voltar"} onClick={onPrevStep} second={true} />
      </div>
    </div>
  </StepDefault>
}

export default SecondStep