// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-anny {
  flex: 1 1;
  background-color: #394937 !important;
  border: none !important;
}

.btn-second {
  flex: 1 1;
  background-color: #a3a3a3 !important;
  border: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Button/Button.css"],"names":[],"mappings":"AAAA;EACE,SAAO;EACP,oCAAoC;EACpC,uBAAuB;AACzB;;AAEA;EACE,SAAO;EACP,oCAAoC;EACpC,uBAAuB;AACzB","sourcesContent":[".btn-anny {\n  flex: 1;\n  background-color: #394937 !important;\n  border: none !important;\n}\n\n.btn-second {\n  flex: 1;\n  background-color: #a3a3a3 !important;\n  border: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
