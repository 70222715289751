const UtilSteps = (step) => {
  switch (step) {
    case 'firstStep':
      return {
        title: 'Qual condicionamento físico mais se assemelha ao seu atual:'
      }
    case 'secondStep':
      return {
        title: 'Quais suas medidas...'
      }

    case 'fourthStep':
      return {
        title: 'Gasto calórico estimado'
      }

    default:
      break;
  }
}

export default UtilSteps