
import { Card } from "react-bootstrap"

const StepDefault = ({ title, children, styleTitle = {} }) => {
  return <Card className={'mt-5 col-12'}>
    <Card.Body>
      <Card.Title children={title} style={styleTitle} />
      {children}
    </Card.Body>
  </Card>
}

export default StepDefault