// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-anny{
  color: #fff;
  border-color: #394937;
  
}

.input-anny:checked{
  border-color: #394937;
  background-color: #394937;
}`, "",{"version":3,"sources":["webpack://./src/partials/Steps/SecondStep/SecondStep.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,qBAAqB;;AAEvB;;AAEA;EACE,qBAAqB;EACrB,yBAAyB;AAC3B","sourcesContent":[".input-anny{\n  color: #fff;\n  border-color: #394937;\n  \n}\n\n.input-anny:checked{\n  border-color: #394937;\n  background-color: #394937;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
