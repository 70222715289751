import React, { useContext } from 'react'
import ContextApp from '../../../context/ContextApp'
import UtilSteps from '../Utils'
import StepDefault from "../../../components/StepDefault/StepDefault"
import Button from '../../../components/Button/Button'
import RenderItem from '../../../components/RenderItemStep/RenderItemStep'
const ThirdStep = ({ onNextStep, onPrevStep }) => {

  const { dataStep3, setDataStep3 } = useContext(ContextApp)
  const { title } = UtilSteps('secondStep')

  const items = [
    {
      id: 0,
      title: "Sedentário (FA= 1.2)",
      subtitle: "Pouco ou nenhum exercício",
      multiple: 1.2
    },
    {
      id: 1,
      title: "Pouco Ativo (FA= 1.4)",
      subtitle: "Exercícios leves ou esportes 1-3 dias / semana",
      multiple: 1.4
    },
    {
      id: 2,
      title: "Moderadamente ativo (FA= 1.5)",
      subtitle: "Exercício moderado ou esportes 3-5 dias / semana",
      multiple: 1.5
    },
    {
      id: 3,
      title: "Muito ativo (FA= 1.7)",
      subtitle: "Exercícios intensos ou esportes de 6-7 dias / semana",
      multiple: 1.7
    },
  ]

  const handleOnClick = (item) => {
    setDataStep3(item)
  }

  return <StepDefault title={title}>
    <>
      {items.map((item) => {

        return <RenderItem item={item} key={`${item.id}_item_indice_0`} onClick={handleOnClick} isActive={dataStep3} />
      })}
      <div className='d-flex mt-4'>
        <Button title={"Avançar"}
          onClick={() => {
            onNextStep()
          }} disabled={!dataStep3} />
      </div>
      <div className='d-flex mt-3'>
        <Button title={"Voltar"} onClick={onPrevStep} second={true} />
      </div>
    </>
  </StepDefault>
}

export default ThirdStep