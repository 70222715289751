import React, { useContext } from 'react'
import ContextApp from '../../../context/ContextApp'
import StepDefault from "../../../components/StepDefault/StepDefault"
import UtilSteps from "../Utils"
import Button from '../../../components/Button/Button'
import "./FirstStep.css"
import RenderItem from '../../../components/RenderItemStep/RenderItemStep'

const FirstStep = ({ onNextStep }) => {

  const { dataStep1, setDataStep1 } = useContext(ContextApp)
  const { title } = UtilSteps('firstStep')


  const items = [
    {
      id: 0,
      title: "abaixo do peso ideal",
      subtitle: "(Harris-Benedict)"
    },
    {
      id: 1,
      title: "próximo ao peso ideal",
      subtitle: "(Harris-Benedict)"
    },
    {
      id: 2,
      title: "muito acima do peso",
      subtitle: "(Mifflin-St Jeor)"
    },
    {
      id: 3,
      title: "atleta",
      subtitle: "(Tinsley)"
    },
  ]

  const handleOnClick = (item) => {
    setDataStep1(item)
  }

  return <StepDefault title={title}>
    <div className="content mt-5">
      {items.map((item) => {
        return <RenderItem item={item} key={`${item.id}_item_indice_0`} onClick={handleOnClick} isActive={dataStep1} />
      })}

    

      <div className='d-flex mt-5'>
        <Button title={"Avançar"} onClick={onNextStep} disabled={!dataStep1} />
      </div>
    </div>
  </StepDefault>
}

export default FirstStep