// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a {
  text-decoration: none !important;
}

p {
  color: #000;
}

.card-item:hover {
  background-color: #394937;
  transition: scale(1.5);
  font-weight: bold;
}

.card-item-active {
  background-color: #394937 !important;
  font-weight: bold;
}

.text-item:hover {
  /* color: white; */
}

.text-item-active {
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/partials/Steps/FirstStep/FirstStep.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;AAClC;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,oCAAoC;EACpC,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd","sourcesContent":["a {\n  text-decoration: none !important;\n}\n\np {\n  color: #000;\n}\n\n.card-item:hover {\n  background-color: #394937;\n  transition: scale(1.5);\n  font-weight: bold;\n}\n\n.card-item-active {\n  background-color: #394937 !important;\n  font-weight: bold;\n}\n\n.text-item:hover {\n  /* color: white; */\n}\n\n.text-item-active {\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
